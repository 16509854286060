import { object, string } from 'zod';

const NO_SPECIAL_CHARACTERS = /^[a-zA-Z0-9\s.,!?:;"']+$/;

export const IDEABOX_SUGGESTION_VALIDATION = object({
    suggestion: string()
        .trim()
        .nonempty('Dit veld is verplicht')
        .max(500, 'Maximaal 500 karakters')
        .refine(
            value => NO_SPECIAL_CHARACTERS.test(value),
            'Alleen de volgende karakters zijn toegestaan: a-z A-Z 0-9 . , ! ? : ; " \'',
        ),
});
