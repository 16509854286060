'use client';

import { Close24Icon } from '@royalaholddelhaize/design-system-pantry-web/assets/icons/ah';
import {
    Modal,
    ModalContent,
    ModalHeader,
    ModalHeaderIconButton,
    ModalHeaderTitle,
} from '@royalaholddelhaize/design-system-pantry-web/components/experimental/modal/modal';

import { IDEABOX_SUGGESTION_VALIDATION } from '@constants/ideabox.validation';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from '@royalaholddelhaize/ah-hook-form';
import { ButtonGroup } from '@royalaholddelhaize/design-system-pantry-web/components/button/button-group/button-group';
import {
    RegularButton,
    RegularButtonText,
} from '@royalaholddelhaize/design-system-pantry-web/components/button/regular-button/regular-button';
import { ErrorMessage } from '@royalaholddelhaize/design-system-pantry-web/components/error-message/error-message';
import { FormRow } from '@royalaholddelhaize/design-system-pantry-web/components/form/form';
import { TextArea } from '@royalaholddelhaize/design-system-pantry-web/components/form/input/text-area/input-text-area';
import { Typography } from '@royalaholddelhaize/design-system-pantry-web/foundation/typography/typography';
import type { FC } from 'react';
import { FormProvider } from 'react-hook-form';
import type { TypeOf } from 'zod';
import css from './contact-popup.module.scss';

type ContactFormValues = TypeOf<typeof IDEABOX_SUGGESTION_VALIDATION>;

interface ContactPopupProps {
    isOpen: boolean;
    setOpenAction: (open: boolean) => void;
}

export const ContactPopup: FC<ContactPopupProps> = ({
    isOpen,
    setOpenAction,
}) => {
    const handleClose = () => {
        setOpenAction(false);
    };

    const methods = useForm<ContactFormValues>({
        resolver: zodResolver(IDEABOX_SUGGESTION_VALIDATION),
    });

    const {
        register,
        handleSubmit,
        reset,
        getFieldError,
        showFieldError,
        formState: { isSubmitted, isValid },
    } = methods;

    const onValid = async (submittedValues: ContactFormValues) => {
        const response = await fetch('/api/suggestion', {
            method: 'POST',
            body: JSON.stringify({
                suggestion: submittedValues.suggestion,
            }),
        });

        if (response.ok) {
            reset();
            return;
        }
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} className={css.modal}>
            {isSubmitted && (
                <>
                    <ModalHeader className={css.modalHeader}>
                        <ModalHeaderTitle>
                            Bedankt voor je idee!
                        </ModalHeaderTitle>
                        <ModalHeaderIconButton
                            onClick={handleClose}
                            aria-label="sluit"
                        >
                            <Close24Icon aria-hidden />
                        </ModalHeaderIconButton>
                    </ModalHeader>
                    <ModalContent className={css.modalContent}>
                        <div className={css.beppie}>
                            <img
                                src="/img/beppie.png"
                                alt="Beppie zegt bedankt"
                                loading="lazy"
                            />
                        </div>
                        <ButtonGroup className={css.action}>
                            <RegularButton
                                type="submit"
                                level="primary"
                                onClick={handleClose}
                            >
                                <RegularButtonText>Afsluiten</RegularButtonText>
                            </RegularButton>
                        </ButtonGroup>
                    </ModalContent>
                </>
            )}
            {!isSubmitted && (
                <>
                    <ModalHeader className={css.modalHeader}>
                        <ModalHeaderTitle>
                            Stuur je projectidee in!
                        </ModalHeaderTitle>
                        <ModalHeaderIconButton
                            onClick={handleClose}
                            aria-label="sluit"
                        >
                            <Close24Icon aria-hidden />
                        </ModalHeaderIconButton>
                    </ModalHeader>
                    <ModalContent className={css.modalContent}>
                        <Typography className={css.description}>
                            Heb je een idee voor een nieuw project of een
                            verbetering van een bestaand project? Laat het ons
                            weten!
                        </Typography>
                        <Typography
                            className={css.additionalDescription}
                            variant="subtext-strong"
                        >
                            Neem je liever contact met ons op via e-mail? Stuur
                            dan een mail naar{' '}
                            <a
                                href="mailto:techlabs@ah.nl"
                                title="Stuur ons een email"
                            >
                                techlabs@ah.nl
                            </a>
                        </Typography>
                        <FormProvider {...methods}>
                            <form
                                onSubmit={handleSubmit(onValid, console.error)}
                            >
                                <FormRow className={css.row}>
                                    <TextArea
                                        id="suggestion"
                                        aria-label={'Project suggestie'}
                                        aria-required
                                        error={showFieldError('suggestion')}
                                        {...register('suggestion')}
                                    />
                                    {showFieldError('suggestion') && (
                                        <ErrorMessage
                                            text={getFieldError('suggestion')}
                                        />
                                    )}
                                </FormRow>
                                <ButtonGroup className={css.action}>
                                    <RegularButton
                                        disabled={!isValid}
                                        type="submit"
                                        level="primary"
                                    >
                                        <RegularButtonText>
                                            Verstuur
                                        </RegularButtonText>
                                    </RegularButton>
                                </ButtonGroup>
                            </form>
                        </FormProvider>
                    </ModalContent>
                </>
            )}
        </Modal>
    );
};
